import './App.scss';
import logo from './horizontal_blue.png';
import axios from "axios";
const App = () => {

    const formSubmit = e => {
        e.preventDefault();


        axios.post('https://test-rs2-integrator-api.avanceesoftware.com/rs2/b4488342c-a346-23cf-ae0g-3e835c32b8d5/save-config', {
            apiKey: e.target[0].value,
            password: e.target[1].value,
            url: e.target[2].value,
            username: e.target[3].value,
        }, )
            .then(function (response) {
                alert('config successfully created');
            })
            .catch(function (error) {
                console.log(error);
            });
    };


  return (
    <div className="App">
        <div className="logo">
            <img src={logo} alt=""/>
        </div>
        <form onSubmit={formSubmit} className="form">
            <label>Api key:</label>
            <input type="password" id="apiKey" name="apiKey" required/>
            <label>Password:</label>
            <input type="password" id="password" name="password" required/>
            <label>Url:</label>
            <input type="text" id="url" name="url" required/>
            <label>Username:</label>
            <input type="text" id="username" name="username" required/>
            <div className="actions">
            <button type="reset">Reset</button>
            <button type="submit">Save</button>
            </div>
        </form>
    </div>
  );
}

export default App;
